import React from 'react'
import MainWrapper from '@/components/PrivacyPolicy&TermsOfUse/MainWrapper'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'

const PrivacyPolicy: React.FC = () => {
  const {
    contentfulPrivacyPolicy: { title, text },
  } = useStaticQuery(graphql`
    {
      contentfulPrivacyPolicy {
        title
        text {
          raw
        }
      }
    }
  `)
  const richText = renderRichText(text, richTextOptions)

  return <MainWrapper title={title} text={richText} />
}

export default PrivacyPolicy
