import React from 'react'
import styled from 'styled-components'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import { graphql, useStaticQuery } from 'gatsby'
import { useWindowSize } from '@/hooks'
import ContentContainer from '@/components/UI/ContentContainer'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 146px;

  p {
    color: white;
    font-size: ${({ theme }) => theme.fontSize.semiTitle};
    font-weight: bold;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
    height: 206px;

    p {
      font-size: ${({ theme }) => theme.fontSize.title};
    }
  }
`
const TextWrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
`
const Text = styled.div`
  max-width: 800px;

  p {
    margin-bottom: 20px;
  }
  h4 {
    margin-block: 50px 20px;
  }
  h5 {
    font-size: ${({ theme }) => theme.fontSize.text};
  }
  ul {
    p {
      margin-bottom: 5px;
    }
    margin-left: 30px;
    list-style-type: initial;
  }
  a {
    word-break: break-all;

    color: ${({ theme }) => theme.colors.blue};
  }
`

type Props = {
  title: string
  text: React.ReactNode
}
const MainWrapper: React.FC<Props> = ({ title, text }) => {
  const { backgroundImage } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "privacyPolicyBg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { width } = useWindowSize()

  return (
    <>
      <BackgroundImageWrapper
        styles={width < 768 ? { backgroundPosition: 'left' } : undefined}
        image={backgroundImage.childImageSharp.fluid}
      >
        <TitleWrapper>
          <p>{title}</p>
        </TitleWrapper>
      </BackgroundImageWrapper>
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
    </>
  )
}

export default MainWrapper
