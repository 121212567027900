import React from 'react'
import Layout from '@/components/layout'
import PrivacyPolicy from '@/components/PrivacyPolicy&TermsOfUse/PrivacyPolicy'
import { Footer } from '@/components/Footer'
import { graphql, useStaticQuery } from 'gatsby'

const PrivacyPolicyPage: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          privacyPolicyPage
        }
      }
    }
  `)

  const { privacyPolicyPage } = nodes[0]

  return (
    <Layout title={privacyPolicyPage}>
      <PrivacyPolicy />
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicyPage
